import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import "@dotlottie/player-component";

const QuantumLogo = ({ style }) => {
    const [position, setPosition] = useState({ x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight });
    const [direction, setDirection] = useState({ x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 });

    useEffect(() => {
        const updatePosition = () => {
            setPosition(prev => {
                let newX = prev.x + direction.x * (Math.random() * 2 + 1);
                let newY = prev.y + direction.y * (Math.random() * 2 + 1);

                if (newX < 0 || newX > window.innerWidth - 40) {
                    setDirection(prev => ({ ...prev, x: -prev.x }));
                    newX = Math.max(0, Math.min(newX, window.innerWidth - 40));
                }
                if (newY < 0 || newY > window.innerHeight - 40) {
                    setDirection(prev => ({ ...prev, y: -prev.y }));
                    newY = Math.max(0, Math.min(newY, window.innerHeight - 40));
                }

                return { x: newX, y: newY };
            });
        };

        const intervalId = setInterval(updatePosition, 50);

        return () => clearInterval(intervalId);
    }, [direction]);

    return (
        <img
            src="/assets/images/PLUR-LOGOMARK.png"
            alt="Plurries Logo"
            style={{
                ...style,
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                transition: 'left 0.05s linear, top 0.05s linear',
            }}
        />
    );
};

const QuantumLogoField = ({ count }) => {
    return (
        <div className="fixed inset-0 pointer-events-none">
            {[...Array(count)].map((_, index) => (
                <QuantumLogo key={index} style={{ width: '20px', height: 'auto' }} />
            ))}
        </div>
    );
};

const PlurriesWebsite = () => {
    const [mainTitleAnimation, setMainTitleAnimation] = useState(null);

    useEffect(() => {
        fetch('/assets/lotties/main_title.json')
            .then(response => response.json())
            .then(data => setMainTitleAnimation(data))
            .catch(error => console.error('Error loading main title Lottie animation:', error));
    }, []);

    const plurryGifs = [
        '/assets/images/plurry1.gif',
        '/assets/images/plurry2.gif',
        '/assets/images/plurry3.gif',
        '/assets/images/plurry4.gif',
        '/assets/images/plurry5.gif',
    ];

    const logoPath = process.env.PUBLIC_URL + '/assets/images/PlurriesHEADER_Logo.png';

    const phrases = [
        "PLUR is peace, love, unity, and respect on-chain",
        "PLUR is a memecoin",
        "PLUR is tokenized ecstasy",
        "PLUR is fun",
        "PLUR will save the internet",
        "PLUR is a manifesto",
        "PLUR is an institution",
        "PLUR is a global shift in paradigm",
        "PLUR is neurodivergent",
        "PLUR is a lifestyle brand",
        "PLUR is the ultimate community",
        "PLUR is speculation",
        "PLUR is divine consciousness",
        "PLUR is the promised land",
        "PLUR is a peace movement",
        "PLUR is an autonomous smart contract",
        "PLUR is happiness",
        "PLUR is an independent record label",
        "PLUR is a decentralized ticketing solution",
        "PLUR is a public good",
        "PLUR mogs",
        "PLUR is the end game",
        "PLUR is a meta",
        "PLUR is acoustic",
        "PLUR is soooooooo",
        "PLUR is based",
        "PLUR is the light at the end of the tunnel",
        "PLUR is the BEST",
        "PLUR is locking in",
        "PLUR is proving them wrong",
        "PLUR is believing in something",
        "God did PLUR"
    ];

    const plurBoxes = ['peace', 'love', 'unity', 'respect'];

    const socialLinks = {
        twitter: "https://x.com/ThePLURToken",
        telegram: "https://t.me/ThePLURToken",
        dexscreener: "https://dexscreener.com/base/0x9c9659700086ecba24566c429cbac27f55461bc1"
    };

    return (
        <div className="min-h-screen font-['Montserrat',_sans-serif] text-black relative bg-gradient-to-b from-pink-200 via-purple-200 via-blue-300 to-blue-500">
            <QuantumLogoField count={50} />
            <div className="relative z-10">
                <div className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 overflow-hidden whitespace-nowrap py-2 md:py-3 marquee-container shadow-md">
                    <div className="marquee-content">
                        {phrases.concat(phrases).map((phrase, index) => (
                            <span
                                key={index}
                                className={`plur-box plur-box-${plurBoxes[index % plurBoxes.length]} mx-1 md:mx-2 text-xs md:text-sm font-bold text-white drop-shadow-md inline-block bg-opacity-30 bg-white rounded-full px-2 md:px-4 py-1`}
                            >
                                {phrase}
                            </span>
                        ))}
                    </div>
                </div>

                <header className="p-4 md:p-8 backdrop-filter backdrop-blur-lg shadow-lg">
                    <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto">
                        <div className="flex items-center space-x-2 md:space-x-4 flex-grow mb-4 md:mb-0">
                            <div className="h-1 bg-white bg-opacity-50 w-8 md:w-12"></div>
                            <img src={logoPath} alt="Plurries logo" className="w-36 md:w-48 h-auto transition-transform duration-300 hover:scale-105" />
                            <div className="h-1 bg-white bg-opacity-50 flex-grow"></div>
                        </div>
                        <div className="flex items-center space-x-4 md:space-x-6">
                            <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/social/twitter.svg" alt="Twitter" className="w-8 h-8 md:w-10 md:h-10 cursor-pointer filter drop-shadow-md hover:drop-shadow-lg transition-all duration-300 hover:scale-110" />
                            </a>
                            <a href={socialLinks.telegram} target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/social/telegram.svg" alt="Telegram" className="w-8 h-8 md:w-10 md:h-10 cursor-pointer filter drop-shadow-md hover:drop-shadow-lg transition-all duration-300 hover:scale-110" />
                            </a>
                            <a href={socialLinks.dexscreener} target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/social/dexscreener.svg" alt="Dexscreener" className="w-8 h-8 md:w-10 md:h-10 cursor-pointer filter drop-shadow-md hover:drop-shadow-lg transition-all duration-300 hover:scale-110" />
                            </a>
                        </div>
                    </div>
                </header>

                <main className="container mx-auto px-4 md:px-6 py-8 md:py-12">
                    <section className="mb-16 md:mb-24">
                        <div className="relative mb-8 md:mb-10">
                            {mainTitleAnimation && (
                                <Lottie
                                    animationData={mainTitleAnimation}
                                    loop={true}
                                    className="w-full max-w-4xl mx-auto drop-shadow-2xl"
                                />
                            )}
                        </div>
                        <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">ABOUT $PLUR</h2>
                        <div className="max-w-3xl mx-auto bg-white bg-opacity-70 rounded-2xl shadow-xl p-6 md:p-8">
                            <p className="mt-4 md:mt-6 text-base md:text-lg text-justify leading-relaxed font-bold">
                                $PLUR is not just another shitcoin; it's an on-chain paradigm shift grounded in Peace, Love, Unity, and Respect. $PLUR challenges the status quo, rejecting corporate norms while uplifting its vibrant community of outcast flowerheads. More than just a currency, $PLUR is a cultural manifesto—a tokenized expression of joy and digital consciousness.
                            </p>
                            <p className="mt-4 md:mt-6 text-base md:text-lg text-justify leading-relaxed">
                                From speculation to innovation, creativity to decentralization, $PLUR is about going against the grain, sticking it to the suits, and making it out the trenches. It is token by the people for the people. Can you dig it, anon?
                            </p>
                        </div>
                    </section>

                    <section className="mb-16 md:mb-24 relative">
                        <div className="flex flex-wrap justify-center gap-4 md:gap-6 mb-8 md:mb-12">
                            {plurryGifs.map((gif, index) => (
                                <img
                                    key={index}
                                    src={gif}
                                    alt={`Plurry ${index + 1}`}
                                    className="w-20 h-20 md:w-32 md:h-32 rounded-full border-4 border-purple-300 hover:border-purple-500 transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105"
                                />
                            ))}
                        </div>
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl md:text-4xl font-bold text-purple-600 opacity-50 animate-pulse">
                            WAIT... WHAT ARE THESE??
                        </div>
                    </section>

                    <section className="mb-16 md:mb-24">
                        <h2 className="text-3xl md:text-4xl font-bold mb-8 md:mb-12 text-center">
                            <span className="text-black">$PLUR TOKENOMICS</span>
                        </h2>
                        <div className="max-w-3xl mx-auto bg-white bg-opacity-70 rounded-2xl shadow-xl p-6 md:p-8 relative overflow-hidden">
                            <div className="mb-6">
                                <h3 className="text-2xl font-bold mb-4">Distribution</h3>
                                <ul className="list-disc pl-6 space-y-2">
                                    <li className="text-lg">80% — Liquidity Pool (LP)</li>
                                    <li className="text-lg">20% — Marketing</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-2xl font-bold mb-4">Contract Address</h3>
                                <p className="text-lg font-mono bg-gray-100 p-2 rounded">0x9c9659700086ecba24566c429cbac27f55461bc1</p>
                            </div>
                            <img
                                src="/assets/images/PLUR-LOGOMARK.png"
                                alt="Plurries Logo"
                                className="absolute bottom-0 right-0 w-1/3 opacity-30"
                            />
                        </div>
                    </section>
                </main>

                <footer className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg text-black p-8 md:p-12 mt-8 md:mt-12 text-center shadow-inner">
                    <img src={logoPath} alt="Plurries logo" className="w-24 md:w-32 mx-auto mb-6 md:mb-8 drop-shadow-lg" />
                    <p className="text-xl md:text-2xl font-bold mb-3 md:mb-4 drop-shadow-lg">Make Web3 Love, Not Web3 War</p>
                    <div className="mt-4">
                        <h4 className="text-lg font-bold mb-2">Disclaimer</h4>
                        <p className="text-sm md:text-base opacity-80">$PLUR is a meme coin with no intrinsic value or expectation of financial return. $PLUR is completely useless and exists solely for entertainment purposes. By purchasing $PLUR, you acknowledge that you have read and understood this disclaimer.</p>
                    </div>
                </footer>

                <div className="bg-gradient-to-r from-purple-600 via-pink-500 to-orange-500 overflow-hidden whitespace-nowrap py-2 md:py-3 marquee-container shadow-md">
                    <div className="marquee-content">
                        {phrases.concat(phrases).map((phrase, index) => (
                            <span
                                key={index}
                                className={`plur-box plur-box-${plurBoxes[index % plurBoxes.length]} mx-1 md:mx-2 text-xs md:text-sm font-bold text-white drop-shadow-md inline-block bg-opacity-30 bg-white rounded-full px-2 md:px-4 py-1`}
                            >
                                {phrase}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlurriesWebsite;